<template>
<div id="default__layout">
<Header/>
<Hero/>
    <slot />
<Footer/>
</div>

</template>

<script>
import Header from '@/components/Header'
import Hero from '@/components/Hero'
import Footer from '@/components/Footer'
export default {
  name: "HomeLayout",
  components: {
    Header,
    Footer,
    Hero

  }
}
</script>

<style scoped>

</style>
