<template>
  <section class="hero__header">
    <Toast position="top-center" />
    <div class="register_container">
      <div class="register_card">
        <div class="form">
          <div class="row">
            <label>Vous êtes:</label>
            <div>
              <SelectButton
                v-model="user.gender"
                :options="options"
                optionLabel="label"
                optionValue="value"
                class="w-full s-button"
              />
            </div>
          </div>
          <div class="row">
            <label>Prénom:</label>
            <div>
              <InputText
                class="w-full"
                v-model="$v.user.firstName.$model"
                :class="{ 'p-invalid': $v.user.firstName.$error }"
              ></InputText>
            </div>
          </div>
          <div class="row">
            <label>Né(e) le:</label>
            <div>
              <InputMask
                v-model="$v.user.birthDate.$model"
                class="w-full"
                :class="{ 'p-invalid': $v.user.birthDate.$error }"
                mask="99/99/9999"
              ></InputMask>
            </div>
          </div>
          <div class="row">
            <label>Mon email</label>
            <div>
              <InputText
                :required="true"
                class="w-full"
                :class="{ 'p-invalid': $v.user.email.$error }"
                v-model="$v.user.email.$model"
              ></InputText>
            </div>
          </div>
          <div class="row">
            <label>Mon mot de passe</label>
            <div>
              <Password
                v-model="user.password"
                toggleMask
                class="sakinapass"
              ></Password>
            </div>
          </div>

          <div class="row">
            <label>Mon téléphone</label>
            <div>
              <InputMask
                v-model="user.phoneNumber"
                class="w-full"
                mask="99 99 99 99 99"
                placeholder="06 12 34 56 78"
                slotChar="_"
              ></InputMask>
            </div>
          </div>

          <div class="row">
            <Checkbox v-model="user.rgpd" :binary="true" />
            <small>
              J'accepte les Conditions Générales d'Utilisation. Mes données sont
              collectées conformément à la Politique de Confidentialité.
            </small>
          </div>
          <div class="row">
            <Button class="btn" @click="signUp">Je m'inscrit !</Button>
          </div>
        </div>
      </div>
      <div style="flex: 1; padding: 20px">
        <h1 class="title text-center">Rencontre Halal pour le mariage</h1>
      </div>
    </div>
  </section>
</template>

<script>
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Toast from 'primevue/toast';
import SelectButton from "primevue/selectbutton";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button/Button";
import InputMask from "primevue/inputmask";
import Password from "primevue/password";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "Hero",

  components: {
    InputText,
    SelectButton,
    Checkbox,
    Button,
    InputMask,
    Password,
    Toast
  },
  data() {
    return {
      user: {
        firstName: null,
        phoneNumber: null,
        email: null,
        gender: null,
        birthDate: null,
        rgpd: false,
      },
      checked: false,
      value1: null,
      options: [
        { label: "Un homme", value: "1" },
        { label: "Une femme", value: "2" },
      ],
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
        minLength: minLength(4),
      },
      firstName: {
        required,
      },
      birthDate: {
        required,
        age18: (value) => {
          const birthdate = new Date(value);
          const today = new Date();
          const age = today.getFullYear() - birthdate.getFullYear();
          return age >= 18;
        },
      },
    },
  },
  methods: {
    signUp: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.$v);
      } else {
        this.$toast.add({
        severity: "success",
        closable: false,
        summary: "Inscription réussie !",
        detail: "Merci de consulter votre mail",
        life: 3000,
      });
      await this.$store.dispatch("signUpAction", this.user);  

      }
    },
  },
};
</script>
<style scoped>
.hero__header {
  background-image: url("../assets/bg-1.png");
  max-width: 1920px;
  /*height: 700px;*/
  background-size: cover;
}

.hero__header::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right,#002f4b,#aaa);
  opacity: .6;
  height: 100%;
}
.title {
  font-size: 7rem;
  line-height: 1;
  font-family: times;
  color: #fff;
  position: relative;
}
.register_card {
  background-color: #fff;
  width: 400px;
  border-radius: 10px;

  /*position: absolute;*/

  padding: 20px;
}
.register_container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  display: flex;
  align-items: center;
}
.btn {
  background-color: #cc1db9;
  color: #fff;
  padding: 10px 50px;
  border-radius: 50px;
  border: none;
  font-size: 1.2rem;
}
.radio-switch {
  background-color: #d1d5db;
  border-radius: 9999px;
  display: inline-flex;
  padding: 0.25rem;
}

.radio-switch__label {
  border-radius: 9999px;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
}

.radio-switch__label--selected {
  /* For selected radio only */
  background-color: #cc1db9;
  color: #fff;
}

.radio-switch__input {
  display: none;
}
.row {
  margin: 10px;
}
input[type="text"] {
  border-radius: 10px;
  border: 1px solid #ccc;
  height: 30px;
  width: 100%;
  padding: 5px 10px;
}
.left {
  text-align: left;
}
.form {
  max-width: 80%;
  margin: auto;
  text-align: left;
}
label {
  font-weight: 700;
}
.s-button .p-button {
  width: 50% !important;
}
.p-password {
  width: 100% !important;
}
</style>
